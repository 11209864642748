"use client";

import { useRouter, useSearchParams } from "next/navigation";

import { SearchForm } from "./SearchForm";

export function Search({
  showLabel,
  background,
}: {
  showLabel?: boolean;
  background?: boolean;
}) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const searchQuery = searchParams.get("sok");

  const updateSearch = (search?: string) => {
    const params = new URLSearchParams();
    if (search) {
      params.append("sok", search);
    }
    router.push(`/upptack?${params.toString()}`);
  };

  return (
    <SearchForm
      initialValue={searchQuery || ""}
      onSubmit={updateSearch}
      showLabel={showLabel}
      background={background}
    />
  );
}
