import { useState } from "react";
import { useTheme } from "next-themes";

import { useModal } from "../../../../../hooks/useModal";
import { Button } from "../../../../Button/Button";
import { Icon } from "../../../../Icon/Icon";
import styles from "./AppearanceMenuitem.module.scss";

const AppearanceMenuItem = () => {
  const { ModalWrapper, isOpen, ref, open, close } = useModal();
  const { theme, setTheme } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);

  const getStatus = () => {
    switch (theme) {
      case "light":
        return "Ljust";
      case "dark":
        return "Mörkt";
      default:
        return "Automatiskt";
    }
  };

  const modal = isOpen ? (
    <ModalWrapper>
      <div className={styles.modalContent} ref={ref}>
        <header className={styles.modalHeader}>
          <div className={styles.modalHeaderTitle}>
            Inställningar för utseende
          </div>
          <button className={styles.modalCloseButton} onClick={(e) => close(e)}>
            <Icon variant="close" size="normal" />
          </button>
        </header>
        <div className={styles.modalBody}>
          <button
            className={styles.selectButton}
            onClick={() => setSelectedTheme("system")}
          >
            <span>
              Automatiskt{" "}
              <span className={styles.checkboxLabelNote}>
                (följer systemets inställninger)
              </span>
            </span>
            <Icon
              variant={
                selectedTheme === "system" ? "checkboxSelected" : "checkbox"
              }
              size="medium"
              className={styles.checkbox}
            />
          </button>
          <button
            className={styles.selectButton}
            onClick={() => setSelectedTheme("dark")}
          >
            Mörkt tema
            <Icon
              variant={
                selectedTheme === "dark" ? "checkboxSelected" : "checkbox"
              }
              size="medium"
              className={styles.checkbox}
            />
          </button>
          <button
            className={styles.selectButton}
            onClick={() => setSelectedTheme("light")}
          >
            Ljust tema
            <Icon
              variant={
                selectedTheme === "light" ? "checkboxSelected" : "checkbox"
              }
              size="medium"
              className={styles.checkbox}
            />
          </button>
        </div>
        <div className={styles.modalFooter}>
          <Button
            size="small"
            variant="primary"
            onClick={(e) => {
              if (e && selectedTheme) {
                setTheme(selectedTheme);
                close(e);
              }
            }}
            slug="footer-close-dialogue-modal"
            text="Ställ in"
          />
        </div>
      </div>
    </ModalWrapper>
  ) : null;

  return {
    type: "button",
    icon: "appearance",
    text: "Utseende",
    onClick: (e: React.UIEvent) => {
      open(e);
    },
    modal,
    status: getStatus(),
  };
};

export { AppearanceMenuItem };
